// noinspection JSUnusedGlobalSymbols,JSUnresolvedVariable

import React from 'react'
import { graphql } from 'gatsby'

import { Layout, SectionContainer, SectionTitle } from '../components/_layout'
import { Grid } from '@material-ui/core'
import { Experiencia } from '../components/Experiencias'


const Experiencias = ( { data } ) => {

  const experiencias = data.experienciasData.edges.map( ( { node } ) => ({
    id: node.id,
    titulo: node.titulo,
    resumen: node.resumen,
    slug: node.slug,
    imagen: node.imagenPrincipal
  }) )

  return (
      <Layout
          withNav
          seoTitle="Experiencias"
          seoDescription="Te invitamos a vivir la experiencia Neminatura."
      >
        <SectionContainer sectionID="experiencias">
          <Grid style={ { height: 64, width: '100%' } }/>
          <SectionTitle title={ 'Experiencias' }/>
          <Grid container>
            { experiencias.map( experiencia => (
                <Experiencia key={ experiencia.id } { ...{ experiencia } } />
            ) ) }
          </Grid>
        </SectionContainer>
      </Layout>
  )
}

export default Experiencias

export const ExperienciasQuery = graphql`
    query ExperienciasQuery {
        experienciasData: allContentfulExperiencias{
            edges {
                node {
                    id
                    titulo
                    resumen
                    slug
                    imagenPrincipal {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                        )
                    }
                }
            }
        } 
}
`
